import rugbyIcon from '../assets/images/sportsbook/icons/rugby.png';
import iceHockeyIcon from '../assets/images/sportsbook/icons/ice-hockey.png';
import badmintonIcon from '../assets/images/sportsbook/icons/badminton.png';
import tableTennisIcon from '../assets/images/sportsbook/icons/table-tennis.png';
import GreyhoundIcon from '../assets/images/sportsbook/icons/greyhound-live.png';
import HorseRacing from '../assets/images/sportsbook/icons/horse_racing.png';

export const EXCHANGE_EVENT_TYPES = [
  { id: '4', name: 'Cricket', slug: 'cricket' },
  { id: '1', name: 'Football', slug: 'football' },
  { id: '2', name: 'Tennis', slug: 'tennis' },
  { id: '7', name: 'Horse Racing', slug: 'horseracing' },
  {
    id: '4339',
    name: 'GreyHound',
    slug: 'greyhound',
  },
  {
    id: '7522',
    name: 'basketball',
    slug: 'basketball',
    enabled: true,
  },
  {
    id: '7511',
    name: 'baseball',
    slug: 'baseball',
    enabled: true,
  },
];

export const EXCH_SPORTS_MAP = {
  tennis: '2',
  football: '1',
  soccer: '1',
  cricket: '4',
  horseracing: '7',
  greyhound: '4339',
  basketball: '7522',
  baseball: '7511',
};

type oEvent = {
  label: string;
  img: string;
};

export const OTHER_EVENTS: oEvent[] = [
  { label: 'Badminton', img: badmintonIcon },
];
