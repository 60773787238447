import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonSpinner } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import './LoginForm.scss';
import {
  fetchBalance,
  loginSuccess,
  loginFailed,
  requestStart,
  requestEnd,
} from '../../store';
import { RootState } from '../../models/RootState';
import { IonLabel } from '@ionic/react';
import API from '../../api';
import ForgotPwdForm from '../ForgotPassword/ForgotPassword';
import Modal from '../Modal/Modal';
import SVLS_API from '../../svls-api';
import { WL_Demo_Domains, demoUser } from '../../util/stringUtil';
import { DomainConfig } from '../../models/DomainConfig';

type StoreProps = {
  loginSuccess: Function;
  loginFailed: Function;
  requestStart: Function;
  requestEnd: Function;
  fetchBalance: Function;
  domainConfig: DomainConfig;
};

type LoginProps = StoreProps & {
  errorMsg: string;
  loading: boolean;
  loggedIn: boolean;
  modalCloseHandler: Function;
  redirectUrl?: string;
};

const LoginForm: React.FC<LoginProps> = (props) => {
  const {
    errorMsg,
    loading,
    loggedIn,
    loginSuccess,
    loginFailed,
    requestStart,
    requestEnd,
    fetchBalance,
    modalCloseHandler,
    redirectUrl,
    domainConfig,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  const [useAuthenticator, setUseAuthenticator] = useState<boolean>(false);
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);

  let history = useHistory();
  const { search } = useLocation();
  const authToken = new URLSearchParams(search).get('authToken');
  useEffect(() => {
    if (authToken) {
      getSapToken(authToken);
      const username = authToken?.split('.')?.[1];
      let uname = JSON.parse(window?.atob(username)).sub;
      sessionStorage.setItem('username', uname);
      sessionStorage.setItem('jwt_token', authToken);
      loginSuccess(authToken);
      history.push('/home');
    }
  }, [authToken]);

  const formik = useFormik({
    initialValues: { username: '', password: '', code: '' },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string(),
      code: Yup.string(),
    }),
    onSubmit: async (values) => {
      setLoadLogin(true);
      requestStart();

      try {
        const loginRequest = {
          username: values.username.toLowerCase(),
          password: values.password.trim(),
        };
        const response = await SVLS_API.post('/account/v2/login', loginRequest);
        setLoadLogin(false);
        setLoginResponse(response.data);
        requestEnd();
        getSapToken(response.data);
        sessionStorage.setItem('username', values.username.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);

        localStorage.removeItem(
          `multiMarket_${values?.username?.toLowerCase()}`,
        );

        loginSuccess(response.data);

        let claim = response.data.split('.')[1];
        let status = JSON.parse(window.atob(claim)).sts;

        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

        if (status === 2) {
          history.replace('/terms-and-conditions');
        } else if (status === 4) {
          history.replace('/reset-password');
        }

        if (redirectUrl) {
          console.log(redirectUrl);
          history.push(redirectUrl);
        }
      } catch (err) {
        loginFailed(err.response.data.message);
      } finally {
        requestEnd();
      }
    },
  });

  const handleDemoLogin = async () => {
    setDemoLoading(true);
    setLoadLogin(false);
    requestStart();
    try {
      const response = await SVLS_API.post('/account/v2/login-demo');
      setDemoLoading(false);
      if (response.status == 200) {
        setLoginResponse(response.data);
        requestEnd();

        sessionStorage.setItem('username', demoUser.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);
        console.log(response.data);
        localStorage.removeItem(`multiMarket_${demoUser.toLowerCase()}`);

        loginSuccess(response.data);

        let claim = response.data.split('.')[1];
        let status = JSON.parse(window.atob(claim)).sts;

        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

        if (redirectUrl) {
          history.push(redirectUrl);
        }
      }
    } catch (err) {
      setDemoLoading(false);
      console.log(err);
    }
  };

  const getSapToken = async (token: string) => {
    try {
      const response = await SVLS_API.get(
        `/account/v2/accounts/${
          JSON.parse(window.atob(token.split('.')[1])).aid
        }/sap-token`,
        {
          headers: {
            Authorization: token,
          },
        },
      );
      if (response.status == 200) {
        sessionStorage.setItem('sap_token', response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      history.push('/home');
      modalCloseHandler();
    }
  }, [loggedIn, modalCloseHandler]);

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  const onRedirectToHome = () => {
    modalCloseHandler();
  };

  return (
    <div className="login-form-page">
      <div className="login-form-ctn">
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <div className="card-title">Login</div>
          <span className="usr-input">
            <TextField
              className="login-input-field user-name"
              type="text"
              name="username"
              placeholder="Username"
              variant="outlined"
              error={
                formik.touched.username && formik.errors.username ? true : false
              }
              helperText={
                formik.touched.username && formik.errors.username
                  ? formik.errors.username
                  : null
              }
              {...formik.getFieldProps('username')}
            />
          </span>

          <div className="pwd-input">
            <FormControl
              className="login-input-field pwd-field"
              variant="outlined"
              error={
                formik.touched.password && formik.errors.password ? true : false
              }
            >
              <OutlinedInput
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                name="password"
                {...formik.getFieldProps('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showPasswordClickHandler}
                      onMouseDown={showPasswordClickHandler}
                      className="eyeicon"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formik.touched.password && formik.errors.password ? (
                <FormHelperText error id="my-helper-text">
                  {formik.errors.password}
                </FormHelperText>
              ) : null}
            </FormControl>
          </div>

          {useAuthenticator ? (
            <span className="code-input">
              <IonLabel className="input-labell">Code</IonLabel>
              <TextField
                className="login-input-field user-name"
                type="text"
                name="username"
                variant="outlined"
                {...formik.getFieldProps('code')}
              />
            </span>
          ) : null}

          {errorMsg !== '' ? (
            <span className="login-err-msg">{errorMsg}</span>
          ) : null}

          <div
            className="forgot-pwd-link"
            // onClick={() => setShowForgotPwdModal(true)}
          >
            {/* Forgot Password?{' '} */}
          </div>
          <div className="login-btns">
            <Button
              className="login-form-btn"
              endIcon={
                loading && loadLogin ? <IonSpinner name="lines-small" /> : ''
              }
              type="submit"
              variant="contained"
            >
              Login
            </Button>
            {/* {WL_Demo_Domains[BRAND_NAME.toLowerCase()] === true ? (
                <Button
                  className="login-form-btn"
                  color="primary"
                  endIcon={demoLoading ? <IonSpinner name="lines-small" /> : ''}
                  onClick={handleDemoLogin}
                  variant="contained"
                >
                  Demo Login
                </Button>
              ) : null} */}
          </div>
          {/* <div className="forgot-pwd-link ">Forgot your password?</div> */}
          <div className="bottom-link" onClick={onRedirectToHome}>
            {/* <ArrowBackIcon /> */}
            Back to Home
          </div>
          {/* <div className="login-form-restrictions-info">
            <AgeRestriction />
            <TerritoriesRestriction />
          </div> */}

          <Modal
            open={showForgotPwdModal}
            closeHandler={() => setShowForgotPwdModal(false)}
            title="Forgot Password"
            size="xs"
          >
            <ForgotPwdForm closeHandler={() => setShowForgotPwdModal(false)} />
          </Modal>
        </form>
      </div>
      <div className="loginFooter">
        {domainConfig.signup ? (
          <div>
            Don’t have an account?
            <a href="/register">
              <span>Sign Up</span>
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    errorMsg: state.auth.loginError,
    domainConfig: state.common.domainConfig,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
    loginFailed: (err: string) => dispatch(loginFailed(err)),
    requestStart: () => dispatch(requestStart()),
    requestEnd: () => dispatch(requestEnd()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
